<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item label="审批人：" prop="flowname">
          <el-input
            placeholder="请输入审批人"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.flowname"
          ></el-input>
        </el-form-item>

        <el-form-item label="标题：">
          <el-input
            disabled
            placeholder="请输入标题"
            size="mini"
            class="form-inp"
            v-model="stepname"
          ></el-input>
        </el-form-item>

        <el-form-item label="审批意见：">
          <el-input
            type="textarea"
            placeholder="请输入审批意见"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.comment"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName', 0)" type="success">
          同意
        </el-button>
        <el-button @click="on_preserve('formName', 1)" type="danger">
          拒绝
        </el-button>
        <el-button @click="on_preserve('formName', 2)" type="warning">
          取消
        </el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  flowenginerefuse,
  flowenginenext,
  flowenginecancel,
  flowenginesaveInfo,
} from "../../assets/request/api";
export default {
  name: "student_redacy",
  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  data() {
    return {
      stepname: "",
      id: "",
      options: [],
      status_list: [],
      formLabelAlign: {
        comment: "",
      },
    };
  },
  methods: {
    way_show(id, stepname) {
      this.stepname = stepname;
      this.id = id;
      let froms = {};
      froms.id = id;
      flowenginesaveInfo(froms).then((res) => {
        console.log(res);
      });
    },
    on_preserve(formName, type) {
      console.log(type);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id } = this;
          formLabelAlign.id = id;
          let url;
          switch (type) {
            case 0:
              url = flowenginenext;
              break;
            case 1:
              url = flowenginerefuse;
              break;
            default:
              url = flowenginecancel;
          }
          url(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.$emit("update:refbool", false);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
</style>