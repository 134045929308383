<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form label-width="120px" :model="formLabelAlign" class="from">
            <el-form-item label="名称：">
              <el-input
                placeholder="请输入名称"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select
                class="form-inp"
                v-model="formLabelAlign.type"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

       
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_tableData(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个审批</span>
            <span class="span2">已选择 {{ more_List.length }} 个审批</span>
            <i @click="way_tableData" class="span_i el-icon-refresh"
              >数据更新</i
            >
          </p>
          <div>
            <el-button @click="on_passList" type="success">一键通过</el-button>
          </div>
        </div>
        <div>
          <el-table
            highlight-current-row
            @current-change="way_singleselect"
            @selection-change="way_moreselect"
            :data="table_List"
            :header-cell-style="{ background: '#f8f8f9' }"
            style="flex: 1"
            height="200px"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="stepname" label="名称"></el-table-column>
            <el-table-column
              prop="begin_time"
              label="开始时间"
            ></el-table-column>
            <el-table-column prop="end_time" label="结束时间"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <!-- <el-link
                  class="list_sty"
                  type="primary"
                  @click="on_pop_addplus(scope.row)"
                  >审批</el-link
                > -->
                <el-link class="list_sty" type="primary" @click="on_journal()"
                  >日志</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination
            @current-change="way_page"
            :page-size="page_size"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
     
      </div>
      <!-- 新建修改弹窗 -->
      <el-dialog
        :title="title"
        show-close
        :visible.sync="dialog_show"
        center
      >
        <examineredact
          v-if="dialog_show"
          ref="examineredact"
          :refbool.sync="dialog_show"
          @way_tableData="way_tableData"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import examineredact from "../view_redact/examine_redact.vue";
import { flowenginenotDone } from "../../assets/request/api";
export default {
  name: "thought_education",
  mixins: [table_minin],
  components: { examineredact },
  data() {
    return {
      title: "",
      dialog_show: false,
      is_extend: true,
      enable_list: [],
      formLabelAlign: {
        name: "",
        type: "",
        bengin_time: "",
        end_time: "",
      },
    };
  },
  created() {
    this.url = flowenginenotDone;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "type_list";
    this.way_tableData();
  },
  methods: {
    way_extend(data) {
      this.enable_list = data.enable_list;
    },
    on_pop_addplus(row) {
      let {id,stepname} = row
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.examineredact.way_show(id,stepname);
      });
    },
    //一键通过
    on_passList() {
      let { more_List } = this;
      if (more_List.length == 0) {
        this.$message.error("请选择后在进行一键通过");
        return;
      }
      let that = this;
      this.$confirm("是否确认一键通过当前选择的", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let more = [];
          more_List.forEach((item) => {
            more.push(item.id);
          });
          let id = more.join(",");
          // that.delete_Url({ id: id }).then((res) => {
          //   if (res.code == 0) {
          //     this.$message({
          //       type: "success",
          //       message: "删除成功!",
          //     });
          //     that.way_tableData();
          //   }
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消一键通过",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 180px;
}
.paging {
  text-align: center;
  padding: 10px;
}
.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
.span1 {
  font-size: 14px;
  color: #000000;
}
.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}
.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}
.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.list_sty {
  margin-right: 10px;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
</style>
