<template>
  <div class="table_sty">
    <div class="froms" style="margin: 5px">
      <p class="describe">首页</p>
      <div class="segmentation"></div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="待办任务" name="first">
          <backlog />
        </el-tab-pane>
        <el-tab-pane label="审批任务" name="second">
          <examine />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import backlog from "../../components/personal_detailssplit/backlog";
import examine from "../../components/personal_detailssplit/examine";

export default {
  name: "thought_education",
  components: { backlog, examine },
  data() {
    return {
      activeName: "first",
    };
  },
  created() {},
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #0788ff;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}




</style>
